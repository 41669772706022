import { useEffect, useState, useContext, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'
import { CFormRange, CListGroup } from '@coreui/react'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import { MdZoomOutMap } from 'react-icons/md'
import { factories, models, Report, service } from 'powerbi-client'
import { useDebouncedCallback } from 'use-debounce'
import M from 'materialize-css';
import MenuReport from 'components/MenuReport'
import { Context } from 'contexts/context'
import { handleReport, handleReportsByUserWithCard } from 'services/api/reports'
import { handleCreateUserActionReport, handleUpdateUserActionReport } from 'services/api/usersActionsReports'
import { handlePowerBiExportReport, handlePowerBiReportAad, handlePowerBiReportEmbed } from 'services/api/powerbi'

import { PowerBIEmbed } from 'powerbi-client-react'

import './style.css'

const Page = () => {
  const history = useHistory()
  const [reportsData, setReportsData] = useState([])
  const powerBiRef = useRef(null)

  const checkInactivity = () => {
    const expireTime = localStorage.getItem("expireTime")

    if (expireTime < Date.now()){
        if (user.roles === 'admin') {
          history.push('/grupos')
        } else {
          history.push('/relatorios')
        }
    }
}

  const updateExpireTime = () => {
    const expireTime = Date.now() + 1200000;
    localStorage.setItem("expireTime", expireTime)
  }

  useEffect(() =>{
    const interval = setInterval(() => {
        checkInactivity()
    }, 60000)
    
    return () => clearInterval(interval)
}, [])

  useEffect(() =>{
      updateExpireTime()

      window.addEventListener("click", updateExpireTime)
      window.addEventListener("keypress", updateExpireTime)
      window.addEventListener("scroll", updateExpireTime)
      window.addEventListener("mousemove", updateExpireTime)

      return () => {
      window.removeEventListener("click", updateExpireTime)
      window.removeEventListener("keypress", updateExpireTime)
      window.removeEventListener("scroll", updateExpireTime)
      window.removeEventListener("mousemove", updateExpireTime)
      }
  },[])

  const [data, setData] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [userActionReportId, setUserActionReportId] = useState()

  const [isFitToPage, setIsFitToPage] = useState(true)
  const [rangeValue, setRangeValue] = useState(50)

  const debouncedRangeValue = useDebouncedCallback(
    async (value) => {
      setRangeValue(Number(value))
      await report.setZoom((value * 0.039) + 0.1)
    },
    500
  )

  const params = useParams()


  const { user, report, setReport, portrait, setPortrait ,setTopBarShow } = useContext(Context)
  const { group_id, report_id, dataset_id } = params
  const screenOrientation = screen.orientation

  const getData = async () => {
    if (user.roles === 'admin') { 

      // const reportData = await handlePowerBiReportAad({ group_id, report_id, dataset_id })
      
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        // roles: ''
      })
      // setData({
      //   reportId: reportData.reportId,
      //   embedUrl: reportData.embedUrl,
      //   token: reportData.accessToken,
      //   // type: models.TokenType.Aad
      //   type: models.TokenType.Embed
      // })

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration
      })

      return reportData.accessToken;
    }

    const roles = await handleReport(report_id, user.id)

    if (roles.roles) {
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        roles: roles.roles
      })

      handleUserAction(roles.report_card_id)

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        page_navigation: roles.page_navigation,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration
      })

      return reportData.embedToken;
    }

    // const reportData = await handlePowerBiReportAad({ group_id, report_id })

    // handleUserAction(roles.report_card_id)

    // setData({
    //   reportId: reportData.reportId,
    //   embedUrl: reportData.embedUrl,
    //   token: reportData.accessToken,
    //   page_navigation: roles.page_navigation,
    //   type: models.TokenType.Aad
    // })

    const reportData = await handlePowerBiReportEmbed({
      group_id,
      report_id,
      dataset: dataset_id,
      username: user.email,
      // roles: roles.roles
    })

    handleUserAction(roles.report_card_id)

    setData({
      reportId: reportData.reportId,
      embedUrl: reportData.embedUrl,
      token: reportData.embedToken,
      page_navigation: roles.page_navigation,
      type: models.TokenType.Embed,
      expiration: reportData.embedToken.expiration
    })

    return reportData.embedToken;
  }

  async function fetchData() {
    if (user) getData()
    setReportsData(await handleReportsByUserWithCard())
  }

 
  const handleUserAction = async (report_card_id) => {
    const userActionReportResponse =
      await handleCreateUserActionReport({ report_id: report_card_id })

    setUserActionReportId(userActionReportResponse)
  }

  useBeforeunload(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }
  })

  const unblock = history.block(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }

    setReport(null)
    unblock()
  })

  // if (window.screen) {
  //    window.screen.orientation.onchange = event => {
        
  //        if (event.target.type === "landscape-primary") {
  //          report.fullscreen()

  //          return
  //        }

  //        if (document.fullscreenElement) report.exitFullscreen()
  //      }

  // } else if (screen.orientation) {
  //   screen.orientation.addEventListener("change", event => {
  //   if (event.target.type === "landscape-primary") {
  //       report.fullscreen();
  //       return;
  //   }

  //   if (document.fullscreenElement) {
  //       report.exitFullscreen();
  //   }

    const width = window.innerWidth;
    const height = window.innerHeight;

    const [minHeight, setMinHeight] = useState('100dvh');
    
    useEffect(() => {

      if ((screenOrientation.type === "landscape-primary" || screenOrientation.type === "landscape-secondary") && width < 900) {
        setPortrait(false)
        setTopBarShow(false)
        setIsFitToPage(false)
        setMinHeight('100dvh')
      }
      else {
        setPortrait(true)
        setTopBarShow(true)
        setIsFitToPage(true)
        setMinHeight('calc(100dvh - 90px)')
        }
    },[report, report_id])

  const reportElement = document.querySelector('.report')

  if (report) {
  screenOrientation.addEventListener("change", async event => {
    if (report.fullscreen || report.exitFullscreen) {
      if 
      (event.target.type === "landscape-primary" || event.target.type === "landscape-secondary") {
        setPortrait(false)
        setTopBarShow(false)
        setIsFitToPage(false)
        reportElement.style.minHeight = '100dvh'
        } 
        else {
        setPortrait(true)
        setTopBarShow(true)
        setIsFitToPage(true);
        reportElement.style.minHeight = 'calc(100dvh - 90px)'
            }
  }
})} else {
  document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elems);
  });
  const handleWindowSizeChange = () => {
    
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width > height && report) {
        report.fullscreen();
    } else if (report) {
        report.exitFullscreen();
    }
};

window.addEventListener('resize', handleWindowSizeChange);
handleWindowSizeChange();
}

let embedConfig

if (user.roles === 'admin') {
embedConfig = {
  type: 'report',
  id: data.reportId,
  embedUrl: data.embedUrl,
  // comentar no GENERATE TOKEN
  eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
  accessToken: data.token,
  // permissions: models.Permissions.All,
  tokenType: data.type || 
  models.TokenType.Aad, 
  // GENERATE TOKEN 
  // models.TokenType.Embed,
  settings: {
    layoutType: models.LayoutType.Custom,
    customLayout: {
      displayOption: models.DisplayOption[`${isFitToPage ? "FitToPage" : "FitToWidth"}`],
    },
    panes: {
      filters: {
        visible: false
      },
      pageNavigation: {
        visible: data.page_navigation ? data.page_navigation : false
      }
    }
  }
}
} else {
embedConfig = {
    type: 'report',
    id: data.reportId,
    embedUrl: data.embedUrl,
    // comentar no GENERATE TOKEN
    eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
    accessToken: data.token,
    permissions: models.Permissions.All,
    tokenType: data.type || 
    models.TokenType.Aad, 
    // GENERATE TOKEN 
    // models.TokenType.Embed,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption[`${isFitToPage ? "FitToPage" : "FitToWidth"}`],
      },
      panes: {
        filters: {
          visible: false
        },
        pageNavigation: {
          visible: data.page_navigation ? data.page_navigation : false
        }
      }
    }
  }
}
  const handleOnChange = (event) => {
    debouncedRangeValue(event.target.value)
  }

  const handleFit = () => {
    setIsFitToPage(!isFitToPage)
  }

  const handlePlusZoom = async () => {
    if (rangeValue < 100) {
      const newRangeValue = rangeValue + 5 > 100 ? 100 : rangeValue + 5
      setRangeValue(newRangeValue)

      await report.setZoom((newRangeValue * 0.039) + 0.1)
    }
  }

  const handleMinusZoom = async () => {
    if (rangeValue > 0) {
      const newRangeValue = rangeValue - 5 < 0 ? 0 : rangeValue - 5
      setRangeValue(newRangeValue)

      await report.setZoom((newRangeValue * 0.039) + 0.1)
    }
  }

  useEffect(() => {
    if (isLoaded) report?.getZoom().then(response => setRangeValue(Number(((response - 0.1) / 0.039).toFixed(0))))
  }, [isFitToPage, isLoaded])

  const selectReport = async (embedObject) => {
    await setReport(embedObject);
  };

  useEffect(async () => {
    fetchData()
    // resetEmbed()
  }, [user, report_id])

  // const resetEmbed = () => {
  //   setEmbedKey(report_id);
  // };

  return (
    <>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {/* <MenuReport
          history={history}
          reportsData={reportsData}
          resetEmbed={resetEmbed}
        /> */}
        <div className='containerReport' 
        style={{minHeight: minHeight, width: '100%'}}
        >
          <PowerBIEmbed
            // key={embedKey}
            ref={powerBiRef}
            eventHandlers={
              new Map([
                ['loaded', () => console.log("Report loaded")],
                ['rendered', () => setIsLoaded(true)],
                ['error', event => console.log(event.detail)],
                ['buttonClicked', () => updateExpireTime()],
                ['visualClicked', () => updateExpireTime()],
                ['selectionChanged', () => updateExpireTime()],
                ['commandTriggered', () => updateExpireTime()],
                ['dataSelected', () => updateExpireTime()],
                ['pageChanged', () => updateExpireTime()],
                ['dataHyperlinkClicked', () => updateExpireTime()],
              ])
            }
            getEmbeddedComponent={async (embedObject) => {
              selectReport(embedObject)
            }}
            embedConfig={embedConfig}
            cssClassName={"report"}
          />
          </div>
        </div>
        {isLoaded && (
          portrait ?
            <>
          <div id='reportRangeContainer'>
            <button onClick={handleMinusZoom} className='zoomReportButton'>
              <AiOutlineMinus />
            </button>

            <CFormRange steps={1} value={rangeValue} onChange={handleOnChange} id="reportRange" />

            <button onClick={handlePlusZoom} className='zoomReportButton'>
              <AiOutlinePlus />
            </button>

            <span id='reportRangeValue'>{rangeValue}%</span>

            <button className='zoomReportButton' onClick={handleFit}>
              <MdZoomOutMap />
            </button>
          </div>
          </> :
          <></>
        )}
        
    </>
  )
}

export default Page