import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaEdit } from "react-icons/fa"

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import SmLinkBlue from 'components/Button/SmLinkBlue'

import Td from 'components/Td'

import api from 'services/api/api'

export default function ReportsCollection() {
    const [reportsCollectionsItems, setReportsCollectionsItems] = useState([])
    const [reportsCollection, setReportsCollection] = useState({})

    const params = useParams()

    useEffect(() => {
        (async () => {
          try {
            const { data } = await api.get(`/collections/${params.id}`)
  
            setReportsCollection(data)

            console.log(data)
          } catch(error) {}
        })()
      }, [params.id])

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/collections/${params.id}/reports`)

                // pegar todos reports que estejam tenham esse users_group_id (não deixando-os duplicados)
                setReportsCollectionsItems(data)

                console.log(data)
            } catch(error) {

            }
          })()
    }, [])

    return (
        <>
        <TitleBar label='Grupos' currentPage="" />

        <Content>
            <Card>
                <CardTitle title='Grupo'></CardTitle>

                <Table>
                        <thead>
                        <tr>
                            <th>Nome</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{reportsCollection.name}</td>
                        </tr>
                        </tbody>
                    </Table>
            </Card>

            <Card>
                <CardTitle title="Relatórios"></CardTitle>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>Nome</th>
                            <th>Roles</th>
                            <th>Abas habilitadas</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportsCollectionsItems.map(reportsCollectionItem =>
                            <tr key={reportsCollectionItem.id}>
                                <td>{reportsCollectionItem.id}</td>
                                <td>{reportsCollectionItem.name}</td>
                                <td>{reportsCollectionItem.roles}</td>
                                <td>{reportsCollectionItem.page_navigation ? "Sim" : "Não"}</td>

                                <Td>
                                    <SmLinkBlue to={`/colecoes/${reportsCollection.id}/relatorios/${reportsCollectionItem.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>

    </>
    )
}