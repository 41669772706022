import api from './api'
import { setErrors } from 'utils'

export const handleReportsByUser = async userId =>
  (await api.post(`/reports/list-by-user`, { user_id: userId })).data

export const handleReportsByUserWithCard = async () =>
  (await api.post(`/reports/list-by-user-with-card`)).data

  export const handleReportsByFirmWithCard = async () =>
  (await api.post(`/reports/list-by-firm-with-card`)).data

export const handleReportsByUserWithCardWithoutGroups = async userId =>
  (await api.post(`/reports/list-by-user-with-card-and-no-groups`, { user_id: userId })).data


export const handleReportById = async id => (await api.get(`/reports/${id}`)).data

export const handleReport = async (reportId, userId) =>
  (await api.post(`/reports/show-by-report`, { report_id: reportId, user_id: userId })).data

export const handleStoreReport = async (history, data, userId, setMessages) => {
  const card = JSON.parse(data.card)
  const values = {
    user_id: data.user_id,
    report_card_id: card.id,
    name: card.name,
    roles: data.roles,
    page_navigation: data.page_navigation
  }
  try {
    await api.post(`/reports/store`, values)
    return history.push(`/usuarios/${userId}/relatorios`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateReport = async (history, data, reportId, userId, setMessages) => {
  try {
    await api.put(`/reports/${reportId}/update`, data)
    return history.push(`/usuarios/${userId}/relatorios`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleReportDelete = async (id, userId, history) => {
  await api.delete(`/reports/${id}`)
  return history.push(`/usuarios/${userId}/relatorios`)
}

export const handleReportDeleteUserAccess = async (id) => {
  await api.delete(`/reports/${id}`)
}

export const handleUpdateReportInfo = async (data, report_id, setMessages) => {
  try {
    await api.put(`/reports/${report_id}/updateDataset`, data)
    return 
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateFavoriteReport = async (id, favorite, reportCardId) => {
  await api.put(`/reports/${id}/updateFavorite`, { favorite, report_card_id: reportCardId })
}

export const handleRecentsReportsByUser = async (userId) => {
  try {
    const response = await api.get(`/reports/recents/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching recent reports:', error);
    return [];
  }
};

export const handleFavoritesReportsByUser = async (userId) => {
  try {
    const response = await api.get(`/reports/favorites/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching recent reports:', error);
    return [];
  }
};