import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import BtnRed from 'components/Button/BtnRed'
import BtnOrange from 'components/Button/BtnOrange'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import CardBody from 'components/CardBody'
import Upload from 'components/Form/Upload'
import SelectLabel from 'components/Form/LabelSelect'
import Select from 'components/Form/Select'
import Validation from './validation'
import { handleCardShow, handleCardReport, handleCardDelete, handleCardUpdateFile } from 'services/api/reportCards'
import { handlePowerBiGroups, handlePowerBiReports } from 'services/api/powerbi'
import { Context } from 'contexts/context'
import SelectDepartment from 'components/Form/SelectDepartment'
import { handleCompaniesShow } from 'services/api/companies'
import { handleDepartmentShow, handleStoreDepartment, handleUpdateDepartment } from 'services/api/department'
import IconButton from 'components/Button/IconButton'
import { RiAddLine, RiPencilLine, RiSubtractLine } from 'react-icons/ri'
import ModalNewDepartment from 'components/NewDepartment'
import ModalEditDepartment from 'components/NewDepartment/EditDepartment'
import { handleReportDeleteUserAccess, handleUpdateReportInfo } from 'services/api/reports'
import Table from 'components/Table'
import { getUsersWithCard } from 'services/api/users'
import { Td } from 'components/Td/styled'
import { FaEdit } from 'react-icons/fa'
import { SmLinkBlue } from 'components/Button/styled'
import { deleteGrupAccessToReport, getGroupsWithCard } from 'services/api/collections'
import ModalCards from 'components/Modal/ModalCards'

const Page = () => {

  const history = useHistory()
  const params = useParams()
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [data, setData] = useState({})
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleModalUserAccess, setVisibleModalUserAccess] = useState(false)
  const [visibleModalGroupsAccess, setVisibleModalGroupsAccess] = useState(false)

  const [messagesFile, setMessagesFile] = useState('')
  const [done, setDone] = useState(false)
  const [errors, setErrors] = useState([])

  const [groups, setGroups] = useState([])
  const [reports, setReports] = useState([])
  const [companies, setCompanies] = useState([])
  const [departments, setDepartments] = useState([])
  const [newDepartment, setNewDepartment] = useState(false)
  const [editDepartment, setEditDepartment] = useState(false)
  const [users, setUsers] = useState([])
  const [usersGroups, setUsersGroups] = useState([])
  const [selectedReport, setSelectedReport] = useState([])
  const [userReportId, setUserReportsId] = useState(null)
  const [userGroupReports, setUserGroupReports] = useState(null)
  const { user: { firm_id } } = useContext(Context)

  // async function fetchData() {
  //   setCompanies(await handleCompaniesShow())
  //   setDepartments(await handleDepartmentShow());
  //   setUsers(await getUsersWithCard(params.id))
  //   setUsersGroups(await getGroupsWithCard(params.id))
  //   }

  async function fetchData() {
    let isMounted = true; // Track if the component is mounted
    const companiesData = await handleCompaniesShow();
    const departmentsData = await handleDepartmentShow();
    const usersData = await getUsersWithCard(params.id);
    const usersGroupsData = await getGroupsWithCard(params.id);

    if (isMounted) {
      setCompanies(companiesData);
      setDepartments(departmentsData);
      setUsers(usersData);
      setUsersGroups(usersGroupsData);
    }
    
    return () => { isMounted = false; }; // Cleanup function to set isMounted to false
  }

  useEffect(() => fetchData(),[visibleModalUserAccess, visibleModalGroupsAccess])
  useEffect(() => {    
    (async () => {
      const fetchedData = await handleCardShow(params.id);
      setData(fetchedData); // Ensure data is an object
      // setDone(false);
    })()
  }, [params.id])

  useEffect(() => {
    (async () => {
      await new Promise(async resolve => resolve(await handlePowerBiGroups()))
        .then(res => setGroups(res))
    })()
  }, [])


  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      roles: data.roles,
      group_id: data.group_id,
      report_id: data.report_id,
      dataset_id: data.dataset_id,
      group_dataset_id: data.group_dataset_id,
      department_id: {
        value: data.department_id,
        label: data.department ? data.department.description : 'Sem departamento'
  },
      company_id: {
        value: data.company_id,
        label: data.company ? data.company.description : 'Sem empresa'
}
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: async () => {
        handleCardReport(history, formik.values, params.id, setMessages)
    },
      enableReinitialize: true
  })

  useEffect(() => {
    (async () => {
      if (formik.values.group_id) {
        const fetchedReports = await handlePowerBiReports(formik.values.group_id);
        setReports(fetchedReports);

        const reportSelected = fetchedReports.find(element => element.id === formik.values.report_id);
        setSelectedReport(reportSelected)
        if (reportSelected) {
          formik.setFieldValue('dataset_id', reportSelected.datasetId);
          formik.setFieldValue('group_dataset_id', reportSelected.datasetWorkspaceId);
        }
      }
    })()
  }, [formik.values.group_id, formik.values.report_id])

  useEffect(() => {
    if (formik.values.report_id) {
      if (reports.length > 0) {
      formik.setFieldValue('name', (reports.find(element => element.id === formik.values.report_id)).name)
      // formik.setFieldValue('dataset_id', (reports.find(element => element.id === formik.values.report_id)).datasetId)
      
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.report_id])
 
  const files = async files => {
    setMessagesFile('')
    setErrors([])
    setDone(true)
    await handleCardUpdateFile(files[0], params.id)
    setData(await handleCardShow(params.id))
    setDone(false)
  }

  const handleResetForm = (formik) => {
    formik.resetForm();
  };

  const formikModal = useFormik({
    initialValues: { department: '' },
    onSubmit: async () => {
        handleStoreDepartment(formikModal.values, setMessages)
        handleResetForm(formikModal)
    }
    })

  const formikModalEdit = useFormik({
    initialValues: { department_id: '' },
    onSubmit:async () => {
      const departmentIdValue = formikModalEdit.values.department_id.value
        handleUpdateDepartment({...formikModalEdit.values, department_id: departmentIdValue}, setMessages)
        handleResetForm(formikModalEdit)
    }
    })

  const handleDelete = async () => await handleCardDelete(params.id, history)

  const handleDeleteUserAccess = async () => {
    await handleReportDeleteUserAccess(userReportId)
    setVisibleModalUserAccess(false) 
  } 

  const handleDeleteGroupUserAccess = async () => {
    //TO BE FIXED -> PUXAR GROUP ID E REPORTS
    console.log(userGroupReports)
    await deleteGrupAccessToReport(userGroupReports)
    setVisibleModalGroupsAccess(false) 
  }
  const breadcrumb = [{ path: '/cards', label: 'Index' }]

  function departmentModal() {
    setNewDepartment(true);
  }

  function departmentModalEdit() {
    setEditDepartment(true);
  }

  async function submitModalNew (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  async function submitModalEdit (formik, values){
    try {
      await formik.handleSubmit(values);
      closeModalAndReload()
    }
    catch (error) {
      console.error("Erro ao executar handleSubmit:", error);
    }
  }

  const closeModalAndReload = () => {
    setNewDepartment(false);
    setEditDepartment(false);
    //reloadPage()
    fetchData()
  }

  return (
    <>
      <TitleBar label="Cards" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Editar">
            <BtnOrange onClick={() => setVisibleModal(true)}>Excluir</BtnOrange>
          </CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Select name="group_id" label="Workspaces" formik={formik}
                  options={groups.map(group => ({ value: group.id, label: group.name }))}
                  />
                  {/* <option value="">Selecione...</option> */}
                  {/* {groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)} */}


                <Select 
                defaultValue={{value: "", label: "Selecione..."}}
                name="report_id" label="Relatórios" formik={formik}
                options={
                  reports.map(report => ({ value: report.id, label: report.name }))
                }
                  />
                  {/* <option value="">Selecione...</option> */}
                  {/* {reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)} */}
                {/* </Select> */}
                <Input name="name" label="Nome" formik={formik} />

                <SelectDepartment
                  name="department_id"
                  label="Departamento"
                  value=''
                  defaultValue={{
                    value: formik.initialValues.department_id.value,
                    label: formik.initialValues.department_id.label
                  }}
                  formik={formik}
                  moreItems={[
                    <div key={0} >
                      <IconButton 
                        Icon={RiAddLine} 
                        color='#082127' 
                        text='Criar'
                        onClick={departmentModal}
                        style={{marginLeft: "10px"}}
                        type="button"
                      />
                    </div>,
                    <div key={1}>
                    <IconButton 
                      Icon={RiPencilLine} 
                      color='#082127' 
                      text='Editar'
                      onClick={departmentModalEdit}
                      style={{marginLeft: "10px"}}
                    />
                  </div>
                  ]}
                  options={[
                    {
                      value: '',
                      label: 'Sem departamento'
                    },
                     ...departments.map((d) => ({ value: d.department_id, label: d.description }))
                  ]}
                    />

                {
                  // firm_id === 11
                (firm_id === 11 || firm_id === 19 || firm_id === 20)
                 && ( 
                  <SelectDepartment
                    name="company_id"
                    label="Empresa"
                    value=''
                    defaultValue={{
                      value: formik.initialValues.company_id.value,
                      label: formik.initialValues.company_id.label
                    }}
                    formik={formik}
                    options={[
                      {
                        value: '',
                        label: 'Sem empresa'
                      },
                       ...companies.map((d) => ({ value: d.company_id, label: d.description }))
                    ]}
                    />)}
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>

        <Card>
          <CardTitle title="Usuários com acesso"></CardTitle>
          <Content>
            <Table>
              <thead>
                <tr>
                  <th width="60">#</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Departamento</th>
                  <th width="50">Ações</th>
                </tr>
              </thead>
              <tbody>
              {users.map(item =>
                <tr key={item.reports_id} >
                  <td style={{ minWidth: '60px', maxWidth: '60px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{`#${item.reports_id}`}</td>
                  <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.user_name}</td>
                  <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.user_email}</td>
                  <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.department_description || "Sem departamento"}</td>
                  <td>
                    <BtnOrange style={{ padding: '5px 20px' }} onClick={() => {
                    setVisibleModalUserAccess(true)
                    setUserReportsId(item.reports_id)
                    // setCollectionSelectedId(userReportsCollection.id)
                      }}>
                      <RiSubtractLine />
                    </BtnOrange>
                  </td>
                </tr>
              )}
              </tbody>
              </Table>
          </Content>
        </Card>

        <Card>
          <CardTitle title="Grupos com acesso"></CardTitle>
          <Content>
            <Table>
              <thead>
                <tr>
                  <th width="60">#</th>
                  <th>Nome</th>
                  <th width="50">Ações</th>
                </tr>
              </thead>
              <tbody>
              {usersGroups.map(item =>
                <tr key={item.group_id} >
                  <td style={{ minWidth: '60px', maxWidth: '60px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>
                    {`#${item.group_id}`}</td>
                  <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>
                    {item.group_name}</td>
                  
                  <Td>
                  <SmLinkBlue 
                    style={{ padding: '5px 20px', marginRight: '10px'}} 
                    to={`/colecoes/${item.group_id}/editar`}>
                      <FaEdit />
                  </SmLinkBlue>
                    <BtnOrange style={{ padding: '5px 20px' }} onClick={() => {
                    setVisibleModalGroupsAccess(true)
                    setUserGroupReports(item)
                      }}>
                      <RiSubtractLine />
                    </BtnOrange>
                  </Td>
                </tr>
              )}
              </tbody>
              </Table>
          </Content>
        </Card>


        <Card>
          <CardTitle title="Imagem"></CardTitle>
          <Content>
            <CardBody>
              <Upload
                onUpload={files}
                fileUrl={data.file_url}
                done={done}
                errors={errors}
                setErrors={setErrors}
              />
              <p>{messagesFile}</p>
            </CardBody>
          </Content>
        </Card>

        <ModalNewDepartment
          formMessages={messages.messages}
          isOpen={newDepartment} 
          onClose={() => {
            setNewDepartment(false)
            formikModal.resetForm()
          }} 
          onSubmit={
            // async (values) => { await submitModalNew(props.formikModal, values)}
            (values) => submitModalNew(formikModal, values)
          }
          formikModal={formikModal}
          />

        <ModalEditDepartment
          formMessages={messages.messages}
          isOpen={editDepartment} 
          onClose={() => {
            setEditDepartment(false)
            formikModalEdit.resetForm()
          }} 
          onSubmit={
            async (values) => { await submitModalEdit(formikModalEdit, values)
          }}
          formikModalEdit={formikModalEdit}
          data={departments}
          />
      </Content>

      <ModalCards
        excludeItemMessage={'Deseja excluir esse item?'}
        handleDelete={handleDelete}
        excludeMessage={'Excluir'}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
      />

      <ModalCards
        excludeItemMessage={'Deseja retirar o acesso deste usuário?'}
        handleDelete={handleDeleteUserAccess}
        excludeMessage={'Excluir acesso'}
        visibleModal={visibleModalUserAccess}
        setVisibleModal={setVisibleModalUserAccess}
      />

      <ModalCards
        excludeItemMessage={'Deseja retirar o acesso deste grupo?'}
        handleDelete={handleDeleteGroupUserAccess}
        excludeMessage={'Excluir acesso'}
        visibleModal={visibleModalGroupsAccess}
        setVisibleModal={setVisibleModalGroupsAccess}
      />
    </>
  )
}

export default Page
