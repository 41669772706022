import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Table from 'components/Table'
import Checkbox from 'components/Form/LabelCheckbox'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import api from 'services/api/api'

import { setErrors } from 'utils'

const validation = yup => ({

})

const NewReportCollection = () => {
    const [reportsCollection, setReportsCollection] = useState({})
    const [reportsCollectionItem, setReportsCollectionItem] = useState({})

    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: { 
        name: reportsCollectionItem.name,
        roles: reportsCollectionItem.roles, 
        page_navigation: !!reportsCollectionItem.page_navigation
      },
      enableReinitialize: true,
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => {
        try {
          await api.put(`/collections/${params.collectionId}/reports/${params.id}`, {
            roles: formik.values.roles,
            page_navigation: formik.values.page_navigation
          })

          history.push(`/colecoes/${params.collectionId}/relatorios`)
        } catch (error) {
          setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        }
      }
    })

    useEffect(() => {
      (async () => {
        try {
          const { data } = await api.get(`/collections/${params.collectionId}`)

          setReportsCollection(data)

          console.log(data)
        } catch(error) {}
      })()
    }, [params.collectionId])

    useEffect(() => {
      (async () => {
        try {
          const { data } = await api.get(`/collections/${params.collectionId}/reports/${params.id}`)

          setReportsCollectionItem(data)
          console.log(data)
        } catch(error) {}
      })()
    }, [params.collectionId, params.id])

    return (
        <>
        <TitleBar label="Grupos" currentPage="" />

        <Content>
          <Card>
            <CardTitle title='Grupo'></CardTitle>

            <Table>
                    <thead>
                    <tr>
                        <th>Nome</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{reportsCollection.name}</td>
                    </tr>
                    </tbody>
                </Table>
            </Card>

          <Card>
            <CardTitle title="Editar"></CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Input readOnly name="name" label="Nome" formik={formik}  />

                  <Input name="roles" label="Roles" formik={formik} />

                  {reportsCollectionItem.report_card_id && <Checkbox name='page_navigation' label='Habilitar Abas' formik={formik} checked={reportsCollectionItem.page_navigation} />}
                  
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>
      </>
    )
}

export default NewReportCollection