import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import { FaEdit, FaChartArea } from "react-icons/fa"
import Td from 'components/Td'
import { Context } from 'contexts/context'
import { useContext, useState } from 'react'
import { InputCadastrar } from 'pages/Cards/styled'
import InputWithSearchIcon from 'components/InputSearch'

const Component = props => {
    const { user: { firm_id } } = useContext(Context)

    const [filter, setFilter] = useState('');

    // const filteredData = props.data.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()));

    function includesFilter(value, filter) {
        return value && value.toLowerCase().includes(filter.toLowerCase());
    }

    // ADD NOT INCLUDE DATOTECNOLOGIA EMAILS
    const notDatoEmailOrDatoDiretoria = props.data.filter(item =>
        (firm_id === 11 || firm_id === 18) || 

        ((!item.email.includes('@datotecnologia.com.br') || item.email === 'bi@datotecnologia.com.br') && firm_id !== 11 && firm_id !== 18)
    )

    const filteredData = notDatoEmailOrDatoDiretoria.filter(item =>
        includesFilter(item.name, filter) ||
        includesFilter(item.id.toString(), filter) ||
        includesFilter(item.email, filter) ||
        includesFilter(item.department, filter) ||
        (
            // firm_id === 11
            (firm_id === 11 || firm_id === 19 || firm_id === 20)
         && includesFilter(item.company, filter)) ||
        includesFilter(item.roles, filter)
    );
    
    return (
        <>
            <TitleBar label="Usuários" currentPage="" />
            <Content>
                <Card>
                    <CardTitle title="Listagem">
                        <InputCadastrar >
                            <InputWithSearchIcon 
                            type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                            
                            <LinkBlue to="/usuarios/cadastrar">Cadastrar</LinkBlue>
                        </InputCadastrar>
                    </CardTitle>
                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th >Nome</th>
                                <th>Email</th>
                                <th>Departamento</th>
                                {
                                    // firm_id === 11
                                (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                                && (
                                    <th>Empresa</th>
                                )}
                                <th>Permissão</th>
                                <th>Bloqueado</th>
                                <th width="100">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData
                                .map(item =>
                                <tr key={item.id} >
                                    <td style={{ minWidth: '60px', maxWidth: '60px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{`#${item.id}`}</td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.name}</td>
                                    <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.email}</td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.department || "Sem departamento"}</td>
                                    {
                                        // firm_id === 11
                                    (firm_id === 11 || firm_id === 19 || firm_id === 20) 
                                    && (
                                    <td style={{ minWidth: '150px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.company || "Sem Empresa cadastrada"}</td>
                                )}
                                    <td style={{ minWidth: '60px', maxWidth: '60px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{item.roles}</td>
                                    <td>{item.blocked_user ? "Sim" : ""}</td>
                                    <Td>
                                        <SmLinkBlue to={`/usuarios/${item.id}/editar`}>
                                            <FaEdit />
                                        </SmLinkBlue>
                                        {item.roles === 'user' &&
                                            <SmLinkBlue to={`/usuarios/${item.id}/relatorios`}>
                                                <FaChartArea />
                                            </SmLinkBlue>
                                        }
                                    </Td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>
            </Content>
        </>
    )
}

export default Component