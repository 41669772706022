import styled from 'styled-components'

export const Login = styled.div`
  background-image: url(${props => props.backgroundImage});
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  max-width: 100%;
  max-height: 100vh; 
  overflow-x: hidden;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (max-width: 960px) {
    background-image: none;
  }
`
export const Image = styled.div`
  flex: 3;
  & div {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1440px) {
    flex: 2;
    & div {
      background-position: left top;
    }
  }
  @media (max-width: 960px) {
    display: none;
  }
`
export const Form = styled.div`
  flex: 2;
  padding: 25px;
  display: flex;
  justify-content: center;
  `
export const LoginBox = styled.div`
  padding: .8rem;
  background-color: var(--color-white);
  max-width: 450px;
  max-height: 80%;
  width: 100%;
  border-radius: 50px;

  @media (max-width: 450px) {
    max-width: 100%;
    padding: .2rem;
  }
`
export const Logo = styled.div`
  width: 100%;
  padding: 1.5rem;
  
  display: flex;
  justify-content: center;

  & img {
    width: 10em;
    max-height: 5em;
    margin-bottom: ${props => props.marginBottom}px;
  }

  @media (max-width: 450px) {
    padding: .8rem 0;
  }

  @media (max-width: 350px) {
    padding: 0 .5rem;
  }
`

export const ImgDatoHub = styled.div`
  background-image: url('/logoDatoHub.jpg');
  height: 100px;
  width: 100px;
  background-size: auto;
`;

export const ContainerFormLogin = styled.div`
  display: flex;
  flex-direction: columns;
  align-items: space-between;
`